@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';




.drawerPaper {
  width: 250px;
  background-color: #B59A57;
  color: #fefefe;
  border: none;
}

.content {
  flex-grow: 1;
  min-height: 100vh;
}

.toolbar {
  /* You'll have to add styling here based on Material UI's specifications */
}

/* Add any other styling as needed */

.appBar {
  background-color: #B59A57;

}

.desktop-nav {
  @apply hidden md:flex;
}

/* This makes the mobile navbar displayed on mobile and hidden on larger screens */
.mobile-nav {
  @apply flex md:hidden;
}

body {
  @apply bg-e6e6e6 m-0 font-spartan text-gray-900;
}

code {
  @apply font-mono;
}

/* NavBar container styles */
.navbar {
  @apply bg-[#B59A57] transition-all duration-300 ease-in-out py-4; /* Increased vertical padding */
  margin-bottom: 20px; /* Add this line */
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

/* Link styles */
.nav-link {
  @apply px-6 py-3 text-xl rounded-lg transition-all duration-300 ease-in-out; /* Increased padding and font size */
}

.nav-link:hover {
  @apply bg-white text-[#B59A57];
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  @apply font-bold;
}
/* Name */
#name {
  @apply text-3xl md:text-7xl text-[#B59A57] font-bold mb-8;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  position: absolute;
  top: 20%;
  left: 5%; /* More to the left on mobile */
}

@media (min-width: 768px) { /* 768px is the default breakpoint for 'md' in Tailwind */
  #name {
    left: 15%; /* Reset for medium screens */
  }
}

#title {
  @apply text-2xl md:text-5xl text-[#B59A57] font-bold mb-3;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  position: absolute;
  top: 30%;
  left: 7%; /* More to the left on mobile */
}

@media (min-width: 768px) {
  #title {
    left: 20%; /* Reset for medium screens */
  }
}




.mrpic{
  
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  color: white;
  margin-top: 40px;
}
/* Updated Lottie animation styles */
.lottie-animation {
  position: absolute;
  top: calc(30% + 96px); /* Adjust as needed */
  left: 30%;
  transform: translateX(-50%);
  max-width: 100%;
  height: 200px;
 
}
/* .special1{
  position: absolute;
  top: 20%;
  left: 15%;
}
.special2{
  position: absolute;
  top: 30%;
  left: 20%;
} */

@keyframes slideInDown {
  from {
    transform: translateY(-200%); /* starts off-screen */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* ends at its original position */
    opacity: 1;
  }
}

.animate-slideInDown {
  animation-name: slideInDown;
  animation-duration: 2s;
  animation-fill-mode: forwards; 
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  
  40% {
    transform: translateY(-20px);
  }
  
  60% {
    transform: translateY(-10px);
  }
}

.animate-bounce {
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}



/* Animation to fade in text */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation-name: fadeIn;
  animation-duration: 2s;
}

.animation-delay-2s {
  animation-delay: 2s;
}
.pb-full {
  padding-bottom: 100%;
}



h1 {
  @apply text-6xl text-[#B59A57] mt-8 mb-4;
}



h2 {
  @apply text-5xl text-[#B59A57] mt-6 mb-3;
}

h2::before {
  content: "";
  display: block;
  height: 60px; 
  margin: -60px 0 0; 
  visibility: hidden;
  pointer-events: none; 
}


h3 {
  @apply text-2xl  mt-5 mb-2;
}

/* Paragraphs and general text content */
p,li {
  @apply text-lg my-4 leading-relaxed text-left px-4;  
  @apply md:px-60;
  @apply lg:px-20p;  
  @apply 2xl:px-30p;
}
li{
  list-style-type: disc;
  list-style-position: inside;
}


/* Container for each section of the website (e.g. Education, Experience, etc.) */
.section {
  @apply p-4 rounded-lg bg-white shadow-md my-4;
}

/* Bullet points or lists */
ul {
  @apply list-decimal pl-5;
}

li {
  @apply my-2;
}


